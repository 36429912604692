import { appendParams } from '@gabegabegabe/utils/dist/url';
import { decodeHTML } from 'entities';
import log from '~/modules/log';
import PROXY_URL from '~/globals/proxy-url';
import store from '~/modules/store';

const BASE_URL = `https://www.${'ultimate'}-${'guitar'}.com/`;
const SEARCH_URL = `${BASE_URL}search.php`;
const TAB_URL = BASE_URL.replace('www', 'tabs').replace(/\/$/u, '');
const SIMPLE_TYPES = [
	'Chords',
	'Tabs',
	'Ukelele Chords',
	'Bass Tabs'
];

const getSearchUrl = (terms = '', type = 'title') => PROXY_URL + appendParams(SEARCH_URL, [
	{ name: 'search_type', value: type },
	{ name: 'value', value: terms }
]);

const getPaginatedUrl = (url, n) => `${url}&page=${n}`;

const extractDataFromHtml = html => {
	const start = '<div class="js-store" data-content="';
	const startI = html.indexOf(start);

	let dataStr = html.substring(startI + start.length);

	dataStr = dataStr.substring(0, dataStr.indexOf('"'));
	dataStr = decodeHTML(dataStr);

	try {
		const dataObj = JSON.parse(dataStr);

		return { ...dataObj };
	} catch (err) {
		log.error('Failed to parse search results', err);

		return null;
	}
};

export const fetchTab = async path => {
	const url = PROXY_URL + TAB_URL + path;
	const response = await fetch(url);
	const html = await response.text();
	const dataBlob = extractDataFromHtml(html);

	return {
		artist: dataBlob.store.page.data.tab.artist_name,
		title: dataBlob.store.page.data.tab.song_name,
		content: dataBlob.store.page.data.tab_view.wiki_tab.content
	};
};

export const getTabs = async (terms = '', { type = 'title' } = {}) => {
	store.commit('resetResultCount');

	const searchUrl = getSearchUrl(terms, type);

	const getResultsFromBlob = blob => blob.store.page.data.results
		.filter(result => result.tab_access_type === 'public' && SIMPLE_TYPES.indexOf(result.type) !== -1)
		.map(result => ({
			artist: result.artist_name,
			rating: result.rating,
			votes: result.votes,
			title: result.song_name,
			url: result.tab_url,
			type: result.type
		}));

	const getPaginatedResults = async (url, n) => {
		const response = await fetch(getPaginatedUrl(url, n));
		const html = await response.text();

		const dataBlob = extractDataFromHtml(html);

		const results = getResultsFromBlob(dataBlob);

		store.commit('resultsFetched', results.length);

		if (dataBlob.store.page.data.pagination.current !== dataBlob.store.page.data.pagination.total) {
			const moreResults = await getPaginatedResults(url, n + 1);

			return [...results, ...moreResults];
		}

		return results;
	};

	return await getPaginatedResults(searchUrl, 1);
};
