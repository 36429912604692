import consola from 'consola';
import notify from '@gabeotisbenson/notify';

const loggerFn = (type, ...msgs) => {
	if (msgs.length < 1) return;

	const canLog = logger => type in logger && typeof logger[type] === 'function';

	if (canLog(consola)) consola[type](...msgs);
	if (canLog(notify)) msgs.forEach(msg => notify[type](msg));
};

export default {
	error: (...msgs) => loggerFn('error', ...msgs),
	info: (...msgs) => loggerFn('info', ...msgs),
	warn: (...msgs) => loggerFn('warn', ...msgs),
	log: (...msgs) => loggerFn('log', ...msgs),
	success: (...msgs) => loggerFn('success', ...msgs)
};
