<template lang="pug">
	form.flex.centered
		label.input-wrapper
			span.hidden Search Terms
			input(v-model="terms", type="text", autofocus, aria-label="Search Terms")
		label.button-wrapper
			span.hidden Submit Search
			button(type="submit", @click="submitSearch") {{ submitButtonText | localize }}
</template>

<script>
	export default {
		name: 'SearchBox',
		data () {
			return {
				submitButtonText: {
					en: 'Search',
					cat: 'Recerca'
				},
				terms: this.$route.params.terms || ''
			};
		},
		methods: {
			submitSearch (e) {
				if ('preventDefault' in e && typeof e.preventDefault === 'function') e.preventDefault();
				if (!this.terms) return false;
				this.$router.push(`/search/${this.terms}`);

				return false;
			}
		}
	};
</script>

<style lang="scss" scoped>
	@import "~/scss/breakpoints";

	form {
		width: 100%;
		font-size: 1.4rem;
		margin-bottom: 1rem;

		@media (min-width: $desktop) {
			font-size: 2rem;
		}

		label.input-wrapper {
			flex-grow: 1;

			input {
				border: 0.0625rem solid var(--text-color);
				border-right: none;
				width: 100%;
				outline: 0;
			}
		}

		label.button-wrapper button {
			min-width: 6rem;
			border: 0.0625rem solid var(--text-color);

			&:hover { cursor: pointer; }

			@media (min-width: $desktop) { min-width: 5rem; }
		}
	}
</style>
