import languages from '~/globals/languages';
import log from '~/modules/log';
import { name as pkgName } from '~/../package';
import { singleItem } from '@gabegabegabe/utils/dist/array/reducers';
import { themes } from '~/globals/themes';
import { toKey } from '@gabegabegabe/utils/dist/array/mappers';
import Vue from 'vue';
import Vuex, { Store } from 'vuex';

const THEME_KEY = `${pkgName}/theme`;
const DEFAULT_THEME = themes
	.filter(({ isDefault }) => isDefault)
	.reduce(singleItem);

Vue.use(Vuex);

const getUserLanguage = () => {
	const lang = window.navigator.userLanguage || window.navigator.language;
	if (lang === 'ca') return 'cat';

	return 'en';
};

const setUserTheme = theme => localStorage.setItem(THEME_KEY, JSON.stringify(theme));
const getUserTheme = () => {
	try {
		const themeJson = localStorage.getItem(THEME_KEY);
		if (themeJson) return JSON.parse(themeJson);

		return DEFAULT_THEME;
	} catch (err) {
		log.error(err, 'Could not parse cached theme JSON, falling back to default theme');

		return DEFAULT_THEME;
	}
};
const userTheme = getUserTheme();

const store = new Store({
	state: {
		search: {
			resultsFetched: 0,
			terms: null,
			results: []
		},
		tab: {
			artist: null,
			content: null,
			path: null,
			title: null
		},
		user: {
			language: getUserLanguage(),
			theme: userTheme
		}
	},
	mutations: {
		applyTheme (state, theme) {
			state.user.theme = theme;
		},
		resultsFetched (state, resultCount) {
			let count = resultCount;
			const interval = 20;
			let timer = -1;

			timer = setInterval(() => {
				if (count === 0) {
					clearInterval(timer);
				} else {
					++state.search.resultsFetched;
					--count;
				}
			}, interval);
		},
		resetResultCount (state) {
			state.search.resultsFetched = 0;
		},
		setLanguage (state, language) {
			if (languages.map(l => l.name).indexOf(language) !== -1) state.user.language = language;
		},
		setSearchResults (state, { terms, results }) {
			while (state.search.results.length > 0) state.search.results.pop();
			state.search.terms = terms;
			results.forEach(result => state.search.results.push(result));
		},
		setTab (state, { artist, content, path, title }) {
			state.tab.artist = artist;
			state.tab.content = content;
			state.tab.path = path;
			state.tab.title = title;
		}
	},
	actions: {
		setTheme ({ commit }, theme) {
			setUserTheme(theme);

			const body = document.querySelector('body');
			const disabledThemeSlugs = themes
				.filter(({ slug }) => slug && slug !== theme.slug)
				.map(toKey('slug'))
				.map(slug => `${slug}-theme`);

			body.classList.remove(...disabledThemeSlugs);
			if (theme.slug) body.classList.add(`${theme.slug}-theme`);
			commit('applyTheme', theme);
		}
	}
});

if (themes.map(toKey('name')).indexOf(userTheme.name) === -1) store.dispatch('setTheme', DEFAULT_THEME);
else store.dispatch('setTheme', userTheme);

export default store;
