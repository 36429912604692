<template lang="pug">
	form
		label
			strong Theme
			select(v-model="selectedTheme")
				option(v-for="theme in themes", :key="theme.name", :value="theme") {{ theme.name }}
</template>

<script>
	import { themes } from '~/globals/themes';

	export default {
		name: 'ThemeSelector',
		data: () => ({ themes }),
		computed: {
			selectedTheme: {
				get () { return this.$store.state.user.theme; },
				set (theme) { return this.$store.dispatch('setTheme', theme); }
			}
		}
	};
</script>

<style lang="scss" scoped>
	form {
		text-align: center;

		label {
			display: flex;
			justify-content: center;
			align-items: center;

			strong { margin-right: 1rem; }

			select {
				border: 1px solid var(--text-color);
				background-image: none !important;
				padding-right: 0.375em;
				cursor: pointer;
			}
		}
	}
</style>
