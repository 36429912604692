<template lang="pug">
	section: p {{ text | localize }}
</template>

<script>
	export default {
		name: 'Home',
		data () {
			return {
				text: {
					en: 'Search for tabs using the box above.  No frills, just plain tabs/chords.',
					cat: 'Cerqueu tauletes mitjançant l’entrada anterior. Sense volums, simplement tablatura/acords.'
				}
			};
		}
	};
</script>

<style lang="scss" scoped>
	section p {
		text-align: center;
	}
</style>
