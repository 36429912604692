<template lang="pug">
	div.template-wrapper.flex.column
		div.search-wrapper: SearchBox
		main: router-view
		div.spacer
		form.language-switcher
			label(
				v-for="language in languages",
				:key="language.name",
				:class="userLanguage === language.name ? 'checked' : ''"
			)
				span {{ language.label }}
				input(v-model="userLanguage", type="radio", :value="language.name")
		ThemeSelector
		div
			SiteFooter(
				v-if="userLanguage === 'cat'",
				:git-url="gitUrl",
				:sections-to-include="catalanFooterSections"
			)
				template(slot="made-by")
					span
						|  Feta per&nbsp;
						a(href="https://gabe.xyz") Gabe
						|  amb &hearts;
				template(slot="philosophy")
					span
						|  Tot el codi és&nbsp;
						a(href="https://www.gnu.org/philosophy/free-sw.html") libre
						|  i de codi obert, amb llicència sota&nbsp;
						a(href="https://www.gnu.org/licenses/gpl-3.0.ca.html") llicència
							|  pública GPL
						|  i disponible a&nbsp;
						a(:href="gitUrl") GitLab
				template(slot="privacy-and-license"): span.divisor
					router-link(to="/privacy", data-jslicense="1") Politica de Privacitat
			SiteFooter(v-else, :git-url="gitUrl")
				template(slot="privacy-and-license"): span.divisor
					router-link(to="/privacy", data-jslicense="1") Privacy Policy
</template>

<script>
	import { homepage } from '~/../package';
	import languages from '~/globals/languages';
	import SearchBox from '~/components/SearchBox';
	import SiteFooter from '@gabeotisbenson/gabes-foot';
	import ThemeSelector from '~/components/ThemeSelector';

	export default {
		name: 'DefaultLayout',
		components: { SearchBox, SiteFooter, ThemeSelector },
		data: () => ({
			catalanFooterSections: [
				'made-by-message',
				'philosophy',
				'privacy-and-license'
			],
			gitUrl: homepage,
			languages
		}),
		computed: {
			userLanguage: {
				get () { return this.$store.state.user.language; },
				set (val) { this.$store.commit('setLanguage', val); }
			}
		}
	};
</script>

<style lang="scss" scoped>
	div.search-wrapper { padding-top: 1rem; }

	div.template-wrapper { min-height: 100vh; }

	div > footer { padding-top: 0; }

	form.language-switcher {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 1rem;

		strong { margin-right: 1rem; }

		label {
			border: 0.0625rem solid var(--text-color);
			border-left: none;
			cursor: pointer;
			padding: 0.5em;

			&:first-of-type { border-left: 0.0625rem solid var(--text-color); }

			&.checked {
				color: var(--bg-color);
				background: var(--text-color);
				font-weight: 600;
			}

			input { display: none; }
		}
	}
</style>
