import Home from '~/pages/Home';
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const FourOhFour = async () => await import('~/pages/404');
const Privacy = async () => await import('~/pages/Privacy');
const SearchResults = async () => await import('~/pages/SearchResults');
const Tab = async () => await import('~/pages/Tab');

const router = new VueRouter({
	mode: 'history',
	routes: [
		{ path: '/', component: Home },
		{ path: '/privacy', component: Privacy },
		{ path: '/search/:terms', component: SearchResults },
		{ path: '/tab/:artist/:title', component: Tab },
		{ path: '*', component: FourOhFour }
	]
});

export default router;
