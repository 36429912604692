/**
 * A collection of functions intended to help with common URL operations.
 * @module url
 */

type Param = {
	name: string;
	value: string|number|boolean;
};

/**
 * Appends parameters to a url
 * @function
 * @name appendParams
 * @param {string} url - The url to which parameters should be appended
 * @param {array} params - The parameters to be appended to the url
 * @returns {string} - The new URL
 */
export const appendParams = (url = '', params: readonly Readonly<Param>[] = []): string => params
	.reduce((output: string, param: Readonly<Param>, i: number) => {
		const { name, value } = param;

		// eslint-disable-next-line no-undefined,@typescript-eslint/no-unnecessary-condition
		if (name === undefined) return output;

		let newOutput = output;

		if (i === 0) newOutput += '?';
		else newOutput += '&';

		// eslint-disable-next-line no-undefined,@typescript-eslint/no-unnecessary-condition
		if (value === null || value === undefined) newOutput += `${name}`;
		else newOutput += `${name}=${value.toString()}`;

		return newOutput;
	}, url);
