import 'core-js/stable';
import 'regenerator-runtime/runtime';
import DefaultLayout from '~/layouts/Default';
import router from '~/modules/router';
import store from '~/modules/store';
import { titleCase } from '@gabegabegabe/utils/dist/string';
import Vue from 'vue';

const SINGLE_POINT_MODIFIER = 10;
const DOUBLE_POINT_MODIFIER = 100;

Vue.filter('title-case', titleCase);
Vue.filter('single-point', n => Math.round(n * SINGLE_POINT_MODIFIER) / SINGLE_POINT_MODIFIER);
Vue.filter('double-point', n => Math.round(n * DOUBLE_POINT_MODIFIER) / DOUBLE_POINT_MODIFIER);

Vue.filter('localize', textObj => textObj[store.state.user.language]);

const app = new Vue({
	...DefaultLayout,
	name: 'TabiCat',
	router,
	store
}).$mount('#root');

export default app;
